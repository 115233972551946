import type { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  VoiceEmotion,
} from "@heygen/streaming-avatar";
import { Scene } from "@soulmachines/smwebsdk";
import { usePrevious } from "ahooks";
import IconClose from "components/Icon/IconClose";
import LoaderFadeInOut from "components/Loader/LoaderFadeInOut";
import { CONST } from "lib/const";
import { useIsDesktop } from "lib/device";
import {
  isCimbniagaURL,
  isGaiaURL,
  isKemendagriURL,
  isSreeyaSewuURL,
} from "lib/host";
import Image from "next/image";
import { OpenAI } from "openai";
import React from "react";
import { useEffect, useRef, useState } from "react";

export const InteractiveAvatar = ({
  isCourse = false,
}: {
  isCourse?: boolean;
}) => {
  const isGaiaTenant =
    typeof window !== "undefined" && isGaiaURL(window.location.hostname);
  const isKemendagriTenant =
    typeof window !== "undefined" && isKemendagriURL(window.location.hostname);
  const isCimbniagaTenant =
    typeof window !== "undefined" && isCimbniagaURL(window.location.hostname);
  const isSreeyaSewuTenant =
    typeof window !== "undefined" && isSreeyaSewuURL(window.location.hostname);

  const screen = typeof window !== "undefined" && window.innerWidth;
  const isMobile = screen < 770;
  const isDesktop = useIsDesktop();

  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [debug, setDebug] = useState<string>();
  const [knowledgeId, setKnowledgeId] = useState<string>("");
  const [dataAvatar, setDataAvatar] = useState<StartAvatarResponse>();
  const [text, setText] = useState<string>("");
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatar | null>(null);
  const [chatMode, setChatMode] = useState("text_mode");
  const [isUserTalking, setIsUserTalking] = useState(false);
  const [isVoiceChatActive, setIsVoiceChatActive] = useState(false);
  const [language, setLanguage] = useState<string>("id");
  const [micIsLoading, setMicIsLoading] = useState(false);
  const [disabledMic, setDisabledMic] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const fetchAccessToken = async () => {
    const trialApiKey =
      "ODI1MGFmMDA2ZTZjNDhmN2I3MWY5YTQ0MjM2MThmNjItMTcyNjY0MjUxOQ==";
    const apiKey =
      "YmFjMmRjYTJhZTQ3NGQ1NDljMWU1YzYyOTU5ZjQwODQtMTcyNjU1ODM0OQ==";

    const response = await fetch(
      "https://api.heygen.com/v1/streaming.create_token",
      {
        method: "POST",
        headers: {
          "x-api-key":
            isKemendagriTenant || isCimbniagaTenant || isSreeyaSewuTenant
              ? trialApiKey
              : apiKey,
        },
      }
    );

    const { data } = await response.json();
    return data.token;
  };

  async function startSession() {
    setIsLoadingSession(true);
    const newToken = await fetchAccessToken();

    avatar.current = new StreamingAvatar({
      token: newToken,
    });
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      setDisabledMic(false);
      console.log("Avatar started talking", e);
    });
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
    });
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      setIsLoadingSession(false);
      endSession();
    });
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready:", event.detail);
      setIsLoadingSession(false);
      setStream(event.detail);
    });
    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      setDisabledMic(true);
      console.log(">>>>> User started talking:", event);
      setIsUserTalking(true);
    });
    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      setTimeout(() => {
        console.log(">>>>> User stopped talking:", event);
        setIsUserTalking(false);
      }, 5000);
    });
    try {
      const res = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: "c5c290e4a54444f7b57583682c463376",
        voice: {
          voiceId: "k8F48nPW6GVasZMZaQvD",
          rate: 1.0,
          emotion: VoiceEmotion.EXCITED,
        },
        knowledgeId: "ea46ba7fdf0d4e60a66ebab4d48914d2",
        language: language,
      });

      // 	https://static.heygen.ai/static/streaming.proto

      setDataAvatar(res);
      await avatar.current?.startVoiceChat();
      setIsVoiceChatActive(true);
      // setChatMode("voice_mode");
    } catch (error) {
      console.error("Error starting avatar session:", error);
    }
  }

  async function handleStartVoiceChat(event) {
    event.stopPropagation();

    setMicIsLoading(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    await avatar.current?.startVoiceChat();
    setMicIsLoading(false);
    setIsVoiceChatActive(true);
  }

  function handleCloseVoiceChat(event) {
    event.stopPropagation();

    setMicIsLoading(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    avatar.current?.closeVoiceChat();
    setMicIsLoading(false);
    setIsVoiceChatActive(false);
  }

  async function handleSpeak() {
    setIsLoadingRepeat(true);
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    await avatar.current.speak({ text: text }).catch((e) => {
      setDebug(e.message);
    });
    setIsLoadingRepeat(false);
  }

  async function handleInterrupt() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");

      return;
    }
    await avatar.current.interrupt().catch((e) => {
      setDebug(e.message);
    });
  }

  async function endSession() {
    if (!avatar.current) {
      setDebug("Avatar API not initialized");
      return;
    }

    if (mediaStream.current) {
      mediaStream.current.classList.remove("opacity-100");
      mediaStream.current.classList.add("opacity-0");

      const closeButton = document.getElementById("close-button");
      closeButton!.classList.remove("opacity-100");
      closeButton!.classList.add("opacity-0");

      const micButton = document.getElementById("mic-button");
      micButton!.classList.remove("opacity-100");
      micButton!.classList.add("opacity-0");

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    avatar.current.closeVoiceChat();
    await avatar.current.stopAvatar();
    setStream(undefined);
    setIsVoiceChatActive(false);
  }

  const previousText = usePrevious(text);
  useEffect(() => {
    if (!previousText && text) {
      avatar.current?.startListening();
    } else if (previousText && !text) {
      avatar?.current?.stopListening();
    }
  }, [text, previousText]);

  useEffect(() => {
    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream;
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current!.play();
        setDebug("Playing");
      };
    }
  }, [mediaStream, stream]);

  const OPENAI_APIKEY =
    "sk-svcacct-964PiVIM0NJYKS_-SCWjRWg6aOzhmkTc2qoN9qFsBUAz5BsCouCE3ubbCpJpQT3BlbkFJjBJE7jBQyXnjo-SqW6c2nn9k6qTMGzWkSy7twvlAKC0iWJou7M5ex9GgzN6AA";

  // OpenAI API Configuration
  new OpenAI({
    apiKey: OPENAI_APIKEY, // API key OpenAI
    dangerouslyAllowBrowser: true,
  }); // Inisialisasi OpenAI dengan konfigurasi

  const stopMediaTracks = (stream: MediaStream) => {
    stream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "audio") {
        track.stop(); // Hentikan track audio
      }
    });
  };

  useEffect(() => {
    return () => {
      // Pastikan stream audio dihentikan ketika komponen unmounted
      if (mediaRecorderRef.current) {
        const stream = mediaRecorderRef.current.stream;
        stopMediaTracks(stream);
      }
    };
  }, []);

  return (
    <>
      {!isDesktop &&
        (isGaiaTenant ||
          isKemendagriTenant ||
          isCimbniagaTenant ||
          isSreeyaSewuTenant) &&
        !isCourse && (
          <>
            {stream && (
              <>
                <div
                  onClick={endSession}
                  className={`fixed w-full h-full inset-0 bg-neutral80_to_dark00 transition-all duration-500 ease-in-out ${
                    stream ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ zIndex: 99999999 }}
                >
                  <div
                    className="relative w-full h-full flex items-end justify-center"
                    style={{ zIndex: 999999999 }}
                  >
                    <video
                      webkit-playsinline
                      playsInline
                      onClick={(e) => e.stopPropagation()}
                      className="w-[90vw] h-[55vh] rounded-md object-cover mb-10 transition-all duration-500 ease-in-out opacity-0"
                      id="sm-video"
                      ref={mediaStream}
                      autoPlay
                      onLoadedMetadata={() => {
                        mediaStream.current!.play();
                        setDebug("Playing");
                        mediaStream.current!.classList.remove("opacity-0");
                        mediaStream.current!.classList.add("opacity-100");

                        const closeButton =
                          document.getElementById("close-button");
                        closeButton!.classList.remove("opacity-0");
                        closeButton!.classList.add("opacity-100");

                        const micButton = document.getElementById("mic-button");
                        micButton!.classList.remove("opacity-0");
                        micButton!.classList.add("opacity-100");
                      }}
                      style={{ objectFit: "cover" }}
                    />

                    <button
                      id="close-button"
                      className={`absolute transition-all duration-500 ease-in-out opacity-0 hover:cursor-pointer`}
                      style={{
                        top: "40%",
                        left: "87%",
                      }}
                      onClick={endSession}
                    >
                      <IconClose size={20} color="#D3D2D7" />
                    </button>

                    <div className="absolute bottom-40 flex gap-8">
                      <button
                        disabled={disabledMic}
                        id="mic-button"
                        onClick={
                          isVoiceChatActive
                            ? handleCloseVoiceChat
                            : handleStartVoiceChat
                        }
                        className={`${
                          disabledMic
                            ? "opacity-50 hover:cursor-not-allowed"
                            : "opacity-100 hover:cursor-pointer"
                        } rounded-full p-10 transition-all duration-500 ease-in-out opacity-0`}
                        style={{
                          backgroundColor: isVoiceChatActive
                            ? "#FFFFFF"
                            : "#D0375C",
                        }}
                      >
                        <Image
                          src={`${
                            isVoiceChatActive
                              ? "/images/Microphone-On.svg"
                              : "/images/Microphone-Off.svg"
                          }`}
                          alt="mic"
                          width={24}
                          height={24}
                        />
                      </button>
                    </div>

                    {micIsLoading && (
                      <div
                        className="rounded-full absolute bottom-40"
                        style={{
                          width: "44px",
                          height: "44px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-10 -right-18">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div
              className="fixed bottom-70 right-10"
              style={{
                zIndex: 8,
              }}
            >
              <div
                className="my-10 hover:cursor-pointer"
                onClick={startSession}
              >
                <Image
                  className="hover:cursor-pointer"
                  src={"/images/nathan.png"}
                  alt="nathan"
                  width={220}
                  height={100}
                />

                <div
                  className="rounded-full absolute bottom-18 right-6"
                  style={{ width: "94px", height: "94px" }}
                >
                  <Image
                    className="hover:cursor-pointer rounded-full"
                    src={"/images/nathan-move.gif"}
                    alt="nathan"
                    width={220}
                    height={100}
                  />
                </div>

                {isLoadingSession && (
                  <div
                    className="rounded-full absolute bottom-18 right-6"
                    style={{
                      width: "94px",
                      height: "94px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <div className="relative -bottom-32 -right-48">
                      <LoaderFadeInOut />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

      {isDesktop &&
        (isGaiaTenant ||
          isKemendagriTenant ||
          isCimbniagaTenant ||
          isSreeyaSewuTenant) &&
        !isCourse && (
          <>
            {stream && (
              <>
                <div
                  onClick={endSession}
                  className={`fixed w-full h-full inset-0 bg-neutral80_to_dark00 transition-all duration-500 ease-in-out ${
                    stream ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ zIndex: 99999999 }}
                >
                  <div
                    className="relative w-full h-full flex items-center justify-center"
                    style={{ zIndex: 999999999 }}
                  >
                    <video
                      webkit-playsinline
                      playsInline
                      onClick={(e) => e.stopPropagation()}
                      className="w-[98vw] h-[90vh] rounded-md object-cover mt-60 transition-all duration-500 ease-in-out opacity-0"
                      id="sm-video"
                      ref={mediaStream}
                      autoPlay
                      onLoadedMetadata={() => {
                        mediaStream.current!.play();
                        setDebug("Playing");
                        mediaStream.current!.classList.remove("opacity-0");
                        mediaStream.current!.classList.add("opacity-100");

                        const closeButton =
                          document.getElementById("close-button");
                        closeButton!.classList.remove("opacity-0");
                        closeButton!.classList.add("opacity-100");

                        const micButton = document.getElementById("mic-button");
                        micButton!.classList.remove("opacity-0");
                        micButton!.classList.add("opacity-100");
                      }}
                    />

                    <button
                      id="close-button"
                      className="absolute top-96 right-40 transition-all duration-500 ease-in-out opacity-0 hover:cursor-pointer"
                      onClick={endSession}
                    >
                      <IconClose size={20} color="#D3D2D7" />
                    </button>

                    <div className="absolute bottom-40 flex gap-8">
                      <button
                        disabled={disabledMic}
                        id="mic-button"
                        onClick={
                          isVoiceChatActive
                            ? handleCloseVoiceChat
                            : handleStartVoiceChat
                        }
                        className={`${
                          disabledMic
                            ? "opacity-50 hover:cursor-not-allowed"
                            : "opacity-100 hover:cursor-pointer"
                        } rounded-full p-10 transition-all duration-500 ease-in-out opacity-0`}
                        style={{
                          backgroundColor: isVoiceChatActive
                            ? "#FFFFFF"
                            : "#D0375C",
                        }}
                      >
                        <Image
                          src={`${
                            isVoiceChatActive
                              ? "/images/Microphone-On.svg"
                              : "/images/Microphone-Off.svg"
                          }`}
                          alt="mic"
                          width={24}
                          height={24}
                        />
                      </button>
                    </div>

                    {micIsLoading && (
                      <div
                        className="rounded-full absolute bottom-40"
                        style={{
                          width: "44px",
                          height: "44px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="relative -bottom-10 -right-18">
                          <LoaderFadeInOut />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div
              className="fixed bottom-10 right-40"
              style={{
                zIndex: 999,
              }}
            >
              <div
                className="my-10 hover:cursor-pointer"
                onClick={startSession}
              >
                <Image
                  className="hover:cursor-pointer"
                  src={"/images/nathan.png"}
                  alt="nathan"
                  width={220}
                  height={100}
                />

                <div
                  className="rounded-full absolute bottom-18 right-6"
                  style={{ width: "94px", height: "94px" }}
                >
                  <Image
                    className="hover:cursor-pointer rounded-full"
                    src={"/images/nathan-move.gif"}
                    alt="nathan"
                    width={220}
                    height={100}
                  />
                </div>

                {isLoadingSession && (
                  <div
                    className="rounded-full absolute bottom-18 right-6"
                    style={{
                      width: "94px",
                      height: "94px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <div className="relative -bottom-32 -right-48">
                      <LoaderFadeInOut />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
    </>
  );
};
